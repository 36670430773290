export class User {
  public Id: number = 0
  public UserName: string = ''
  public FirstName: string = ''
  public LastName: string = ''
  public EmailAddress: string = ''

  public displayName(): string {
    let displayName = ''

    if (!!this.FirstName) {
      displayName = this.FirstName
    }

    if (!!this.LastName) {
      if (displayName.length > 0) {
        displayName += ' '
      }

      displayName += this.LastName
    }

    if (displayName.length === 0) {
      displayName = this.UserName
    }

    return displayName
  }
}
