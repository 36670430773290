import { Component, OnInit, ElementRef } from '@angular/core'
import { ROUTES } from '../sidebar/sidebar.component'
import { Location } from '@angular/common'
import { Router } from '@angular/router'
import { ToasterService } from 'src/app/services/toaster.service'
import { UserService } from 'src/app/services/user.service'
import { Md5 } from 'ts-md5'

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  public userName: string
  public userMailHash: string

  public focus
  public listTitles: any[]
  public location: Location
  
  constructor(
    location: Location,
    private router: Router,
    private toaster: ToasterService,
    private userService: UserService
  ) {
    this.location = location
  }

  ngOnInit() {
    this.listTitles = ROUTES.filter(listTitle => listTitle)

    this.userService.userInfoChanged.asObservable().subscribe(change => {
      this.loadCurrentUser()
    })

    this.loadCurrentUser()
  }
  
  getTitle(){
    var titlee = this.location.prepareExternalUrl(this.location.path())
    if(titlee.charAt(0) === '#'){
        titlee = titlee.slice( 1 )
    }

    for(var item = 0; item < this.listTitles.length; item++){
        if(this.listTitles[item].path === titlee){
            return this.listTitles[item].title
        }
    }
    return 'Dashboard'
  }

  async loadCurrentUser() {
    const currentUser = await this.userService.getCurrentUser()
    if (!!currentUser) {
      this.userName = currentUser.displayName()
      this.userMailHash = this.generateUriString(currentUser.EmailAddress)
    }
  }

  executeLogout() {
    this.userService.logout()
    .then(() => {
      this.toaster.success('Logout executed.')
      this.router.navigate(['/login'])
    })
  }

  private generateUriString(mail: string) {
    return Md5.hashStr(mail)
  }
}
