import { Injectable } from "@angular/core"
import { ToastrService } from "ngx-toastr"

@Injectable()
export class ToasterService {
  constructor(
    private toastr: ToastrService
  ) {
  }

  public success(message: string, title?: string) {
    this.toastr.success(message, title, {
      positionClass: "toast-bottom-right"
    })
  }

  public warning(message: string, title?: string) {
    this.toastr.warning(message, title, {
      positionClass: "toast-bottom-right"
    })
  }

  public error(message: string, title?: string) {
    this.toastr.error(message, title, {
      positionClass: "toast-bottom-right"
    })
  }
}