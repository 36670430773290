import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { RouterModule } from '@angular/router'
import { BrowserModule } from '@angular/platform-browser'

import { CookieService } from 'ngx-cookie-service'
import { ToastrModule } from 'ngx-toastr'
import { NgxBootstrapSliderModule } from 'ngx-bootstrap-slider'
import { NgxMatDatetimePickerModule } from '@angular-material-components/datetime-picker'

import { AppComponent } from './app.component'
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component'
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component'

import { NgbModule } from '@ng-bootstrap/ng-bootstrap'

import { AppRoutingModule } from './app.routing'
import { ComponentsModule } from './components/components.module'
import { MatSliderModule } from '@angular/material/slider'
import { MatDatepickerModule } from '@angular/material/datepicker'

import { HttpStatusInterceptor } from './interceptors/HttpStatusInterceptor'
import { UserService } from './services/user.service'
import { LocalStorageService } from './services/local-storage.service'
import { ToasterService } from './services/toaster.service'
import { UserWebService } from './services/web-services/user-web.service'

@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ComponentsModule,
    NgbModule,
    RouterModule,
    AppRoutingModule,
    BrowserModule,
    ToastrModule.forRoot(),
    NgxBootstrapSliderModule,
    MatSliderModule,
    NgxMatDatetimePickerModule,
    MatDatepickerModule
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent
  ],
  providers: [
    // Web Services
    UserWebService,

    // Services
    CookieService,
    UserService,
    LocalStorageService,
    ToasterService,

    // Http Interceptor
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpStatusInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
