import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable, throwError } from "rxjs"
import { catchError, tap } from "rxjs/operators"
import { ToasterService } from "../services/toaster.service"
import { LocalStorageService } from "../services/local-storage.service"

@Injectable()
export class HttpStatusInterceptor implements HttpInterceptor {
  constructor(
    private toasterService: ToasterService,
    private localStorage: LocalStorageService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 0 || err.status >= 500) {
          console.error(`A server error has occurred (${err.status}): ${err.error}`)
        }

        if (err.status === 404) {
          this.toasterService.warning('Ressource not found!')
        }

        // 401: Session invalid -> Remove token from localstore and navigate to login
        if (err.status === 401) {
          this.toasterService.warning('Please login again!', 'Session expired')
          this.localStorage.removeAuthTokenFromLocalStore()
        }

        return throwError(err)
      })
    )
  }
}
