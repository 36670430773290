import { Injectable } from '@angular/core'
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders, HttpParams } from '@angular/common/http'
import { map } from 'rxjs/operators'
import { LocalStorageService } from '../local-storage.service'

@Injectable()
export abstract class BaseWebService {
    private apiUrl: string = 'https://rest.florian-berger.info'

    constructor(
        private http: HttpClient,
        private localStorage: LocalStorageService
    ) { }

    protected getVoid(): Promise<void> {
        return this.http.get(this.apiUrl)
        .pipe(map(() => {}))
        .toPromise()
    }

    protected postVoid(endpoint: string, data: any): Promise<void> {
        return this.http.post(this.apiUrl + endpoint, JSON.stringify(data), { responseType: 'text', reportProgress: false, headers: this.getHeaders() })
        .pipe(map(() => {}))
        .toPromise()
    }

    protected deleteVoid(endpoint: string): Promise<void> {
        return this.http.delete(this.apiUrl + endpoint, { responseType: 'text', reportProgress: false, headers: this.getHeaders() })
        .pipe(map(() => {}))
        .toPromise()
    }

    protected postAndReturnList<TData>(endpoint: string, data: any): Promise<TData[]> {
        const req = new HttpRequest('POST', this.apiUrl + endpoint, JSON.stringify(data), this.requestOptions)
        return this.http.request<TData[]>(req)
        .pipe(map((res: HttpResponse<TData[]>) => res.body ))
        .toPromise()
    }

    protected getList<TData>(endpoint: string): Promise<TData[]> {
        const req = new HttpRequest('GET', this.apiUrl + endpoint, this.requestOptions)
        return this.http.request<TData[]>(req)
        .pipe(map((res: HttpResponse<TData[]>) => res.body))
        .toPromise()
    }

    protected postAndReturn<TData>(endpoint: string, data: any): Promise<TData> {
        const req = new HttpRequest('POST', this.apiUrl + endpoint, JSON.stringify(data), this.requestOptions)

        return this.http.request<TData>(req)
        .pipe(map((res: HttpResponse<TData>) => res.body))
        .toPromise()
    }

    protected getSingle<TData>(endpoint: string): Promise<TData> {
        const req = new HttpRequest('GET', this.apiUrl + endpoint, this.requestOptions)
        return this.http.request<TData>(req)
        .pipe(map((res: HttpResponse<TData>) => res.body))
        .toPromise()
    }

    protected postAndReturnNumber(endpoint: string, data: any): Promise<number> {
        const req = new HttpRequest('POST', this.apiUrl + endpoint, JSON.stringify(data), this.requestOptions)
        return this.http.request<number>(req)
        .pipe(map((res: HttpResponse<number>) => res.body))
        .toPromise()
        .then(count => count || 0)
    }

    protected postAndReturnString(endpoint: string, data: any): Promise<string> {
        const req = new HttpRequest('POST', this.apiUrl + endpoint, JSON.stringify(data), this.requestOptions)
        return this.http.request<string>(req)
        .pipe(map((res: HttpResponse<string>) => {
            if (!res.body) { return res.body }
            if (res.body === 'null') { return null }
            return res.body.slice(1, -1)
        }))
        .toPromise()
    }

    protected getString(endpoint: string): Promise<string> {
        const req = new HttpRequest('GET', this.apiUrl + endpoint, this.requestOptions)
        return this.http.request<string>(req)
        .pipe(map((res: HttpResponse<string>) => {
            if (!res.body) { return res.body }
            if (res.body === 'null') { return null }
            return res.body
        }))
        .toPromise()
    }

    protected getStringWithParams(endpoint: string, data: any): Promise<string> {
        const requestOptions = { headers: this.getHeaders(), params: {} }

        if (data) {
            let params = new HttpParams()
            Object.keys(data).forEach(key => {
                params = params.set(key, data[key])
            })
            requestOptions.params = params
        }

        const req = new HttpRequest('GET', this.apiUrl + endpoint, requestOptions)
        return this.http.request<string>(req)
        .pipe(map((res: HttpResponse<string>) => {
            if (!res.body) { return res.body }
            if (res.body === 'null') { return null }
            return res.body
        }))
        .toPromise()
    }

    protected putAndReturn<TData>(endpoint: string, data: any): Promise<TData> {
        const req = new HttpRequest('PUT', this.apiUrl + endpoint, data, this.requestOptions)
        return this.http.request<TData>(req)
        .pipe(map((res: HttpResponse<TData>) => res.body))
        .toPromise()
    }

    protected deleteAndReturn<TData>(endpoint: string): Promise<TData> {
        const req = new HttpRequest('DELETE', this.apiUrl + endpoint, this.requestOptions)
        return this.http.request<TData>(req)
        .pipe(map((res: HttpResponse<TData>) => res.body))
        .toPromise()
    }

    protected getHttpInstance(): HttpClient {
        return this.http
    }

    private getHeaders(): HttpHeaders {
        let headers = new HttpHeaders()

        headers = headers
            .set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')

            const jwt = this.localStorage.getAuthToken()
            if (jwt.length > 0) {
                headers = headers.set('Authorization', `Bearer ${jwt}`)
            }

        return headers
    }

    private get requestOptions() {
        return {
            observe: 'response',
            reportProgress: false,
            enableCache: false,
            headers: this.getHeaders()
        }
    }
}
