import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { ToasterService } from 'src/app/services/toaster.service'
import { UserService } from 'src/app/services/user.service'
import { Md5 } from 'ts-md5'

declare interface RouteInfo {
    path: string
    title: string
    icon: string
    class: string
    isFontAwesome?: boolean
    fontAwesomeShort?: string
}
export const ROUTES: RouteInfo[] = [
    { path: '/dashboard', title: 'Dashboard',     icon: 'ni-single-copy-04 text-blue', class: '' },
    { path: '/general',   title: 'General',       icon: 'ni-circle-08 text-blue',      class: '' },
    { path: '/stations',  title: 'Work Stations', icon: 'ni-tv-2 text-blue',           class: '' },
    { path: '/projects',  title: 'Projects',      icon: 'fa-link text-blue',           class: '', isFontAwesome: true, fontAwesomeShort: 'fas' },
    { path: '/socials',   title: 'Social Links',  icon: 'ni-world text-blue',          class: '' },

    // { path: '/maps', title: 'Maps',  icon:'ni-pin-3 text-orange', class: '' },
    // { path: '/user-profile', title: 'User profile',  icon:'ni-single-02 text-yellow', class: '' },
    // { path: '/tables', title: 'Tables',  icon:'ni-bullet-list-67 text-red', class: '' },
    // { path: '/login', title: 'Login',  icon:'ni-key-25 text-info', class: '' },
    // { path: '/register', title: 'Register',  icon:'ni-circle-08 text-pink', class: '' }
]

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public userName: string
  public userMailHash: string

  public menuItems: any[]
  public isCollapsed = true

  constructor(
    private router: Router,
    private toaster: ToasterService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem)
    this.router.events.subscribe((event) => {
      this.isCollapsed = true
    })

    this.userService.userInfoChanged.asObservable().subscribe(change => {
      this.loadCurrentUser()
    })

    this.loadCurrentUser()
  }

  executeLogout() {
    this.userService.logout()
    .then(() => {
      this.toaster.success('Logout executed.')
      this.router.navigate(['/login'])
    })
  }

  async loadCurrentUser() {
    const currentUser = await this.userService.getCurrentUser()
    if (!!currentUser) {
      this.userName = currentUser.displayName()
      this.userMailHash = this.generateUriString(currentUser.EmailAddress)
    }
  }

  private generateUriString(mail: string) {
    return Md5.hashStr(mail)
  }
}
