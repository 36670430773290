import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SidebarComponent } from './sidebar/sidebar.component'
import { NavbarComponent } from './navbar/navbar.component'
import { FooterComponent } from './footer/footer.component'
import { RouterModule } from '@angular/router'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { PipesModule } from '../pipes/pipes.module'
import { IdenticonModule } from '../shared/identicon.module'

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    PipesModule,
    IdenticonModule
  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent
  ],
  exports: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent
  ],
  providers: [
  ]
})
export class ComponentsModule { }
