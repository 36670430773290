import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { JDENTICON_CONFIG, NgxJdenticonModule } from "ngx-jdenticon";

@NgModule({
  imports: [
    BrowserModule,
    NgxJdenticonModule
  ],
  providers: [
    {
      provide: JDENTICON_CONFIG,
      useValue: {
        hues: [213],
        lightness: {
          color: [0.47, 0.84],
          grayscale: [0.57, 0.85],
        },
        saturation: {
          color: 0.75,
          grayscale: 0.53,
        },
        backColor: '#fff',
      }
    }
  ],
  exports: [
    NgxJdenticonModule
  ]
})
export class IdenticonModule { }
