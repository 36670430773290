import { EventEmitter, Injectable } from "@angular/core"
import { UserWebService } from "./web-services/user-web.service"
import { User } from "../data/User"
import { LocalStorageService } from "./local-storage.service"
import { ActionResult } from "../data/ActionResult"

@Injectable()
export class UserService {
  public userInfoChanged: EventEmitter<boolean> = new EventEmitter<boolean>()

  constructor(
    private userWebService: UserWebService,
    private localStoreService: LocalStorageService
  ) {
    
  }

  public async isAuthenticated(): Promise<boolean> {
    if (!this.localStoreService.getAuthToken()) {
      return false
    }

    try {
      const currentUser = await this.getCurrentUser()
      return currentUser.Id > 0
    } catch {
      return false
    }
  }

  public getCurrentUser(): Promise<User> {
    return this.userWebService.getCurrentUser()
  }

  public async refreshToken(): Promise<void> {
    try {
      const token = await this.userWebService.refreshToken()
      this.localStoreService.setAuthToken(token?.Token)
    } catch {
      this.localStoreService.removeAuthTokenFromLocalStore()
    }
  }

  public async authenticate(username: string, password: string): Promise<boolean> {
    try {
      const authResult = await this.userWebService.authenticate(username, password)
      if (authResult.Token) {
        this.localStoreService.setAuthToken(authResult.Token)
        return true
      }
    } catch {
      return false
    }
  }

  public async logout(): Promise<boolean> {
    this.localStoreService.removeAuthTokenFromLocalStore()
    return Promise.resolve(true)
  }

  public updateUser(username: string, firstname: string, lastname: string): Promise<ActionResult> {
    return this.userWebService.updateUser(username, firstname, lastname)
  }

  public updatePassword(oldPassword: string, newPassword: string): Promise<ActionResult> {
    return this.userWebService.updatePassword(oldPassword, newPassword)
  }

  public updateEmailAddress(password: string, newMailAddress: string): Promise<ActionResult> {
    return this.userWebService.updateEmailAddress(password, newMailAddress)
  }

  public emitUserChanged() {
    this.userInfoChanged.emit(true)
  }
}
