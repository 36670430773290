import { Injectable } from "@angular/core"
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from "@angular/router"
import { Observable } from "rxjs"
import { UserService } from "./services/user.service"

@Injectable()
export class NotAuthGuard implements CanActivate, CanActivateChild {
    constructor(
        private userService: UserService,
        private router: Router
    ) { }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return this.checkLogin(state.url)
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return this.checkLogin(state.url)
    }

    private checkLogin(desiredUrl: string): Promise<boolean | UrlTree> {
        return this.userService.isAuthenticated()
            .then((result: boolean) => {
                if (!result) { return true }

                console.log(`Already authenticated. Redirect to dashboard`)
                this.router.navigate(['/dashboard'])
                return false
            })
    }
}