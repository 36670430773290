import { Component, OnInit } from '@angular/core'
import { UserService } from 'src/app/services/user.service'

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit {
  private tokenRefreshInterval: any

  constructor(
    private userService: UserService
  ) { }

  ngOnInit() {
    this.startTokenUpdateTimer()
  }

  private startTokenUpdateTimer() {
    // I want to update the JWT every 5 minutes - so start the interval!
    this.tokenRefreshInterval = setInterval(() => {
      this.updateToken()
    }, 5 * 60 * 1000)
  }

  private updateToken() {
    this.userService.refreshToken()
  }
}
