import { Injectable } from "@angular/core"
import { BaseWebService } from "./base-web.service"
import { User } from "src/app/data/User"
import { TokenResult } from "src/app/data/TokenResult"
import { ActionResult } from "src/app/data/ActionResult"

@Injectable()
export class UserWebService extends BaseWebService {
  public getCurrentUser(): Promise<User> {
    return super.getSingle<User>('/user/current')
      .then(r => Object.setPrototypeOf(r, new User))
  }

  public refreshToken(): Promise<TokenResult> {
    return super.getSingle<TokenResult>('/user/updateToken')
  }

  public authenticate(username: string, password: string): Promise<TokenResult> {
    return super.postAndReturn<TokenResult>('/user/authenticate', { username, password })
  }

  public updateUser(username: string, firstname: string, lastname: string): Promise<ActionResult> {
    return super.postAndReturn<ActionResult>('/user/data', { username, firstname, lastname })
  }

  public updatePassword(oldPassword: string, newPassword: string): Promise<ActionResult> {
    return super.postAndReturn<ActionResult>('/user/password', { oldPassword, newPassword })
  }

  public updateEmailAddress(password: string, newMailAddress: string): Promise<ActionResult> {
    return super.postAndReturn<ActionResult>('/user/email', { password, newMailAddress })
  }
}
