import { Injectable } from "@angular/core"

@Injectable()
export class LocalStorageService {
  //#region Keys

  private readonly authTokenKey: string = 'authToken'
  private readonly lastUserNameKey: string = 'lastUserName'

  //#endregion Keys

  //#region Auth Token

  public setAuthToken(authToken: string) {
    // When no token was passed, remove the auth token from local store
    if (!authToken) {
      this.removeAuthTokenFromLocalStore()
      return
    }

    localStorage.setItem(this.authTokenKey, authToken)
  }

  public getAuthToken(): string {
    return localStorage.getItem(this.authTokenKey) || ''
  }

  public removeAuthTokenFromLocalStore() {
    localStorage.removeItem(this.authTokenKey)
  }

  //#endregion Auth Token

  //#region Last Username

  public setLastUserName(userName: string) {
    localStorage.setItem(this.lastUserNameKey, userName)
  }

  public getLastUserName(): string {
    return localStorage.getItem(this.lastUserNameKey) || ''
  }

  //#endregion Last Username

}
